import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {Box, Button, IconButton, Paper, TextField, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PageSizeOptions} from "@/data/options";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {getOrders, GetOrdersParams, PostOrderBody, postOrdersBatch} from "@/api/order";
import {
    OneOffOrderTimeslot,
    Order, orderStatusUI,
    RegularOrderTimeslot
} from "@models/order";
import {Student} from "@models/student";
import {getStudents} from "@/api/student";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {CsvExportButton} from "@/components/CsvExportButton";
import {CsvImportButton} from "@/components/CsvImportButton";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {StudentSelect} from "@/components/StudentSelect";
import {Pagination} from "common/models/pagination";
import {GetAccountRecordsParams} from "@/api/account";

export default function OrderList() {

    const {snack, setSnack} = useContext(SnackbarContext);

    const columns: GridColDef<Order>[] = [
        {
            field: '_id',
            headerName: '訂單ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'courseId',
            headerName: '課程ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'status',
            headerName: '狀態',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => orderStatusUI[row.status].title
        },
        {
            field: 'firstName',
            headerName: '學生名字',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => (studentLookup[row.studentId] ? studentLookup[row.studentId].firstName : "-")
        },
        {
            field: 'lastName',
            headerName: '學生姓氏',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => (studentLookup[row.studentId] ? studentLookup[row.studentId].lastName : "-")
        },
        {
            field: 'createdAt',
            headerName: '創建日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => dayjs(row.createdAt).format('DD/MM/YYYY')
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='primary'
                            size='small'
                            sx={{ ml: 0.5 }}
                            component={RouterLink}
                            to={`/orders/edit/${row._id}`}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })
    const [query, setQuery] = useState<GetOrdersParams & Partial<Pagination>>({
        skip: paginationModel.pageSize * paginationModel.page,
        limit: paginationModel.pageSize,
        count: true
    })

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.ORDER_LIST, query],
        async () => {
            return (await getOrders(query)).data
        }
    )

    const orderList = useMemo<Order[]>(() => {
        if (!data)
            return []
        return data.orders.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
    }, [data])

    const [studentLookup, setStudentLookup] = useState<{[key: string]: Student}>({})
    useEffect(() => {
        getStudents({
            studentIds: [...new Set(orderList.map(o => o.studentId))]
        })
            .then(res => {
                const lookup = res.data.students.reduce((l, student) => {
                    l[student._id] = student
                    return l
                }, {} as {[key: string]: Student})
                setStudentLookup(lookup)
            })
            .catch(e => {

            })
    }, [orderList]);

    /**
     * CSV Import
     * */

    const importTransformer = (row: string[]): PostOrderBody => {
        const timeslotStr = row[2]
        let timeslots: OneOffOrderTimeslot[] | RegularOrderTimeslot[] = []
        if (timeslotStr.length !== 0) {
            if (timeslotStr.includes('/'))
                timeslots = timeslotStr.split(',').map(t => ({
                    date: t.split('/')[0] || '',
                    month: t.split('/')[1] || ''
                }))
            else
                timeslots = timeslotStr.split(',').map(t => ({
                    lessonId: Number(t.split('-')[0] || ''),
                    timeslotId: Number(t.split('-')[1] || '')
                }))
        }

        return {
            // Required.
            courseId: Number(row[0]),
            studentId: row[1],
            timeslots,
            price: Number(row[3]),
            status: Number(row[4]),
            // Optional.
            // classId
            // paymentMethod: 1,
            // paidAt
            // refundedAt
        }
    }

    const importResultChanged = async (result: PostOrderBody[]) => {
        try {
            await postOrdersBatch({
                orders: result
            })
            setSnack(Snack.success('成功匯入'))
        }
        catch (e) {
            setSnack(Snack.error('匯入失敗'))
        }
        await refetch()
    }

    /***
     * Row actions
     */

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRows(rowSelectionModel);
    };

    return (
        <>
            <Button variant="contained" component={RouterLink} to="/orders/add">
                新增
            </Button>

            <CsvExportButton sx={{ml: 2}}
                             disabled={selectedRows.length === 0}
                             fileName={"orders"}
                             header={[]}
                             rows={orderList.filter(o => selectedRows.includes(o._id)).map(o => ([
                                 o._id,
                                 o.courseId,
                                 studentLookup[o.studentId] ? studentLookup[o.studentId].firstName : "-",
                                 studentLookup[o.studentId] ? studentLookup[o.studentId].lastName : "-",
                                 dayjs(o.createdAt).format('DD/MM/YYYY')
                             ]))}/>

            <CsvImportButton<PostOrderBody> sx={{ml: 2}}
                                            transformer={importTransformer}
                                            resultChanged={importResultChanged}/>

            <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ mb: 2 }}
                >
                    篩選
                </Typography>

                <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
                    <TextField sx={{ mr: 1 }}
                               fullWidth={true}
                               label="搜索訂單ID"
                               variant="outlined"
                               size="small"
                               value={query.keyword || ""}
                               onChange={(e) => setQuery({...query, keyword: e.target.value as string})}
                    />

                    <StudentSelect
                        size="small"
                        fullWidth={true}
                        value={query.studentId || null}
                        onChange={(e, value) => setQuery({...query, studentId: value as string})}
                        textFieldProps={{
                            name: "studentId",
                            label: "學生"
                        }}
                    ></StudentSelect>
                </Box>

                <Box sx={{display: "flex", alignItems: "center"}}>
                    <DateTimePicker label="開始時間"
                                    slotProps={{
                                        textField: {size: "small"},
                                        field: { clearable: true }
                                    }}
                                    value={query.since ? dayjs(query.since) : null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    onChange={(newValue) => setQuery({...query, since: newValue?.valueOf() || 0})}/>
                    <Typography component='div' sx={{ mx: 1 }}>-</Typography>
                    <DateTimePicker label="完結時間"
                                    slotProps={{
                                        textField: {size: "small"},
                                        field: { clearable: true }
                                    }}
                                    value={query.until ? dayjs(query.until) : null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    onChange={(newValue) => setQuery({...query, until: newValue?.valueOf() || 0})}/>
                </Box>
            </Paper>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={orderList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                checkboxSelection
                onRowSelectionModelChange={handleRowSelectionChange}
            />
        </>
    )
}
