import {
    Box,
    Button,
    IconButton,
    Typography,
} from "@mui/material";
import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {FormikErrors, useFormik} from "formik";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {CreateOneOffLessonPayload, OneOffLesson, OneOffLessonTimeslot} from "@models/course";
import {TutorSelect} from "@/components/TutorSelect";
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import dayjs from "dayjs";
import ClearIcon from "@mui/icons-material/Clear";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {getOneOffLessons, patchOneOffLesson, postOneOffLesson} from "@/api/course";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {ConfirmModal} from "@/components/ConfirmModal";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch } from "@/store";
import {getTutorLookup} from "@/store/slices/tutor";
import { useTutorStore } from "@/hooks/useTutorStore";
import {formatOneOffLessonTimeslotStartEnd} from "@/helpers/format";

export type CourseLessonProps = {
    courseId: number
}

export const CourseLesson: FC<CourseLessonProps> = (props) => {

    const {
        courseId
    } = props

    const {snack, setSnack} = useContext(SnackbarContext)

    const columns: GridColDef[] = [
        {
            field: 'tutorId',
            headerName: '導師ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'timeslots',
            headerName: '日期選項',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }: { row: OneOffLesson }) => {
                return (
                    <Box sx={{ width: '100%' }}>
                        { row.timeslots.map(t => (
                            <Box key={t.id}>
                                <Box>
                                    { tutorLookup[t.tutorId] ? tutorLookup[t.tutorId].name : '-' }
                                </Box>
                                <Box>
                                    { formatOneOffLessonTimeslotStartEnd(t) }
                                </Box>
                            </Box>
                        )) }
                    </Box>
                )
            }
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='primary' size='small'
                            onClick={() => clickEdit(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.ONE_OFF_LESSON_LIST, {courseId}],
        async () => {
            return (await getOneOffLessons({
                courseId
            },{
                count: true,
            })).data
        }
    )

    const lessonList = useMemo(() => {
        if (!data)
            return []
        return data.lessons.map(l => ({
            id: l._id, // Add id for MUI
            ...l
        }))
    }, [data])

    /**
     * Init
     * */

    const dispatch = useAppDispatch()

    const {
        tutorLookup
    } = useTutorStore()

    useEffect(() => {
        dispatch(getTutorLookup())
    }, [])

    /***
     * Row actions
     */

    const clickEdit = (row: OneOffLesson) => {
        handleOpenModal(row)
    }

    /***
     * Details Modal
     */

    const formik = useFormik({
        initialValues: {
            tutorId: "",
            timeslots: [] as OneOffLessonTimeslot[]
        },
        onSubmit: async (values) => {
            console.log('onSubmit')
            console.log(values)
            try {
                if (editingOneOffLesson) {
                    await patchOneOffLesson({
                        courseId: courseId,
                        lessonId: editingOneOffLesson.lessonId
                    }, {
                        tutorId: values.tutorId,
                        timeslots: values.timeslots.map(t => ({
                            tutorId: t.tutorId,
                            start: t.start,
                            end: t.end,
                        }))
                    })
                }
                else {
                    await postOneOffLesson({
                        courseId: courseId
                    }, values)
                }
                setSnack(Snack.success('成功儲存'))
                handleCloseModal()
                await refetch()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: CreateOneOffLessonPayload) => {
            let errors = {
                tutorId: chainRules([requiredInputStringRule], values.tutorId),
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })
    const [editingOneOffLesson, setEditingOneOffLesson] = useState<OneOffLesson | null>(null)
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = useCallback((lesson?: OneOffLesson) => {
        formik.resetForm()
        if (lesson)
            formik.setValues(Object.assignExists({
                tutorId: "",
                timeslots: [] as OneOffLessonTimeslot[]
            }, lesson))
        setEditingOneOffLesson(lesson || null)
        setOpenModal(true)
    }, [formik])
    const handleCloseModal = () => setOpenModal(false)
    const handleAddTimeslot = () => {
        formik.setFieldValue("timeslots", [...formik.values.timeslots, {
            tutorId: formik.values.tutorId,
            start: dayjs().startOf("hour").valueOf(),
            end: dayjs().startOf("hour").add(1, "hour").valueOf()
        }])
    }
    const handleRemoveTimeslot = (index: number) => {
        const timeslots = [...formik.values.timeslots]
        timeslots.splice(index)
        formik.setFieldValue("timeslots", timeslots)
    }

    return (
        <>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                // loading={isLoading}
                rows={lessonList}
                columns={columns}

                // pagination
                // paginationMode='server'
                // paginationModel={paginationModel}
                rowCount={data?.count || 0}
                // pageSizeOptions={PageSizeOptions}
                // onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            />

            <Box>
                {/*                    {`第${index + 1}堂`}*/}
                <Button sx={{ mt: 2 }}
                        variant="contained"
                        fullWidth={true}
                        onClick={() => handleOpenModal()}>
                    新增課堂
                </Button>
            </Box>


            {/* Modal */}
            <ConfirmModal title={`${editingOneOffLesson && editingOneOffLesson._id ? '修改' : '新增'}課堂`}
                          open={openModal}
                          onClose={handleCloseModal}
                          confirmButtonTitle={'儲存'}
                          confirmButtonProps={{
                              disabled: !formik.dirty || formik.isSubmitting
                          }}
                          onSubmit={formik.handleSubmit}>
                <TutorSelect
                    sx={{mt: 2}}
                    name="tutorId"
                    label="導師"
                    size="small"
                    fullWidth={true}
                    value={formik.values.tutorId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.tutorId && formik.touched.tutorId}
                    helperText={formik.errors.tutorId}/>

                <Typography
                    component='div'
                    sx={{ mt: 2 }}
                >
                    日期選項
                </Typography>
                {
                    formik.values.timeslots.map((t, i) => (
                            <Box sx={{mt: 2, display: "flex", alignItems: "center"}} key={`time-range-${i}`}>
                                <Typography
                                    component='div'
                                    sx={{ mr: 1 }}
                                >
                                    { `${i+1}.` }
                                </Typography>
                                <Box>
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        <DateTimeField label="開始時間"
                                                       size="small"
                                                       value={dayjs(t.start)}
                                                       format={"DD/MM/YYYY HH:mm"}
                                                       onChange={(newValue) => formik.setFieldValue(`timeslots.${i}.start`, newValue)}/>
                                        <Typography component='div' sx={{ mx: 1 }}>-</Typography>
                                        <DateTimeField label="完結時間"
                                                       size="small"
                                                       value={dayjs(t.end)}
                                                       format={"DD/MM/YYYY HH:mm"}
                                                       onChange={(newValue) => formik.setFieldValue(`timeslots.${i}.end`, newValue)}/>
                                    </Box>
                                    <TutorSelect
                                        sx={{ mt: 2 }}
                                        name={`timeslots.${i}.tutorId`}
                                        label="導師"
                                        size="small"
                                        fullWidth={true}
                                        value={formik.values.timeslots[i].tutorId}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={!!formik.errors.timeslots && !!((formik.errors.timeslots as FormikErrors<OneOffLessonTimeslot>[])[i].tutorId) && !!formik.touched.timeslots && !!formik.touched.timeslots[i].tutorId}
                                        helperText={formik.errors.timeslots && (formik.errors.timeslots as FormikErrors<OneOffLessonTimeslot>[])[i].tutorId}/>
                                </Box>
                                <IconButton
                                    color='inherit'
                                    aria-label='open drawer'
                                    edge='start'
                                    onClick={() => handleRemoveTimeslot(i)}
                                    sx={{ ml: 2 }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Box>
                    ))
                }

                <Button sx={{mt: 2}}
                        variant="contained"
                        fullWidth={true}
                        onClick={handleAddTimeslot}>
                    新增日期選項
                </Button>
            </ConfirmModal>

        </>
    )
}
