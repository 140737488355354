import axios from "axios";
import {Admin} from "@models/admin";
import {Pagination} from "common/models/pagination";
import {SuccessRes} from "@/model/success-res";

/***
 * @POST
 * /admins/login
 */

export interface PostAdminsLoginBody {
    email: string,
    password: string,
}

export interface PostAdminsLoginRes {
    userId: string,
    accessToken: string,
    accessExpiresIn: number,
    refreshToken: string,
    refreshExpiresIn: number,
}

export async function postAdminsLogin(data: PostAdminsLoginBody) {
    return await axios.post<PostAdminsLoginRes>('/admins/login', data)
}

/***
 * @POST
 * /admins/refresh-token
 */

export interface PostAdminsRefreshTokenBody {
    refreshToken: string,
}

export interface PostAdminsRefreshTokenRes {
    accessToken: string;
    accessExpiresIn: number;
    refreshToken?: string;
    refreshExpiresIn?: number;
}

export async function postRefreshToken(data: PostAdminsRefreshTokenBody) {
    return await axios.post<PostAdminsRefreshTokenRes>('/admins/refresh-token', data)
}

/***
 * @GET
 * /admins
 */

export interface GetAdminsParams {
    keyword?: string,
    active?: boolean
}

export interface GetAdminsRes {
    admins: Admin[],
    count?: number
}

export async function getAdmins(params: GetAdminsParams & Partial<Pagination>) {
    return await axios.get<GetAdminsRes>('/admins', {
        params,
        token: true
    })
}

/***
 * @GET
 * /admins/{userId}
 */

export interface GetAdminPathParams {
    id: string
}

export interface GetAdminRes {
    admin: Admin
}

export async function getAdmin(pathParams: GetAdminPathParams) {
    return await axios.get<GetAdminRes>(`/admins/${pathParams.id}`, {
        token: true
    })
}

/***
 * @POST
 * /admins
 */

export interface PostAdminsBody {
    name: string,
    email: string,
    password: string
}

export interface PostAdminsRes {
    adminUser: Admin
}

export async function postAdmins(data: PostAdminsBody) {
    return await axios.post<PostAdminsRes>('/admins', data, {token: true})
}

/***
 * @PATCH
 * /admins/{userId}
 */

export interface PatchAdminsPathParams {
    id: string
}

export interface PatchAdminsData {
    name?: string,
    email?: string
}

export async function patchAdmins(pathParams: PatchAdminsPathParams, data: PatchAdminsData) {
    return await axios.patch<SuccessRes>(`/admins/${pathParams.id}`, data, {token: true})
}

/***
 * @PATCH
 * /admins/{userId}/password
 */

export interface PatchAdminPasswordPathParams {
    id: string
}

export interface PatchAdminPasswordData {
    password: string
}

export async function patchAdminPassword(pathParams: PatchAdminPasswordPathParams, data: PatchAdminPasswordData) {
    return await axios.patch<SuccessRes>(`/admins/${pathParams.id}/password`, data, {token: true})
}

/***
 * @PATCH
 * /admins/self
 */

export type PatchAdminSelfData = PatchAdminsData

export async function patchAdminSelf(data: PatchAdminSelfData) {
    return await axios.patch<SuccessRes>('/admins/self', data, {token: true})
}

/***
 * @PATCH
 * /admins/self/password
 */

export interface PatchAdminSelfPasswordData {
    oldPassword: string
    password: string
}

export async function patchAdminSelfPassword(data: PatchAdminSelfPasswordData) {
    return await axios.patch<SuccessRes>('/admins/self/password', data, {token: true})
}

/***
 * @POST
 * /admins/{userId}/activate
 */

export interface PostAdminsActivatePathParams {
    id: string
}

export async function postAdminsActivate(pathParams: PostAdminsActivatePathParams) {
    return await axios.post<SuccessRes>(`/admins/${pathParams.id}/activate`, {}, {token: true})
}

/***
 * @POST
 * /admins/{userId}/deactivate
 */

export interface PostAdminsDeactivatePathParams {
    id: string
}

export async function postAdminsDeactivate(pathParams: PostAdminsDeactivatePathParams) {
    return await axios.post<SuccessRes>(`/admins/${pathParams.id}/deactivate`, {}, {token: true})
}

/***
 * @GET
 * /admins/names
 */

export interface GetAdminNamesParams {
    userIds: string[]
}

export type GetAdminNamesRes = {[key: string]: string}

export async function getAdminNames(params: GetAdminNamesParams) {
    return await axios.get<GetAdminNamesRes>('/admins/names', {
        params,
        token: true
    })
}


