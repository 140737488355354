import React, {useContext, useMemo, useState} from "react";
import {SnackbarContext} from "@/context/SnackbarContext";
import {Box, Paper, Typography} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {getTutorStudentDashboard, GetTutorStudentDashboardParams} from "@/api/tutor";
import { LineChart } from '@mui/x-charts/LineChart';

export default function Dashboard() {

    const { snack, setSnack } = useContext(SnackbarContext);

    const [query, setQuery] = useState<GetTutorStudentDashboardParams>({

    })

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.DASHBOARD, query],
        async () => {
            return (await getTutorStudentDashboard(query)).data
        }
    )

    const lineChartMonths = useMemo(() => {
        if (!data)
            return []
        return [...new Set(data.dashboard.map(item => item.months).flat().map(m => m.month))].sort()
    }, [data])

    const lineChartXAxis= useMemo(() => {
        return [
            {
                id: 'Month',
                data: lineChartMonths,
                // scaleType: 'time',
                // valueFormatter: (date) => date.getFullYear().toString(),
            },
        ]
    }, [lineChartMonths])

    const lineChartSeries = useMemo(() => {
        if (!data)
            return []
        return data.dashboard.map(item => ({
            label: item.name,
            data: lineChartMonths.map(m => {
                const month = item.months.find(month => month.month === m)
                return month ? month.students : 0
            }),
        }))
    }, [lineChartMonths])

    return (
        <>
            <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ mb: 2 }}
                >
                    篩選
                </Typography>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <DateTimePicker label="開始時間"
                                    slotProps={{
                                        textField: {size: "small"},
                                        field: { clearable: true }
                                    }}
                                    value={query.since ? dayjs(Number(query.since)) : null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    onChange={(newValue) => setQuery({...query, since: newValue ? `${newValue.valueOf()}` : undefined})}/>
                    <Typography component='div' sx={{ mx: 1 }}>-</Typography>
                    <DateTimePicker label="完結時間"
                                    slotProps={{
                                        textField: {size: "small"},
                                        field: { clearable: true }
                                    }}
                                    value={query.until ? dayjs(Number(query.until)) : null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    onChange={(newValue) => setQuery({...query, until: newValue ? `${newValue.valueOf()}` : undefined})}/>
                </Box>
            </Paper>

            {/*<LineChart*/}
            {/*    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}*/}
            {/*    series={[*/}
            {/*        {*/}
            {/*            label: 'Tutor A',*/}
            {/*            data: [2, 5.5, 2, 8.5, 1.5, 5],*/}
            {/*        },*/}
            {/*        {*/}
            {/*            label: 'Tutor B',*/}
            {/*            data: [3, 4, 8, 1, 2, 5],*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*    width={500}*/}
            {/*    height={300}*/}
            {/*/>*/}

            <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
                <LineChart
                    xAxis={lineChartXAxis}
                    series={lineChartSeries}
                    width={500}
                    height={300}
                />
            </Paper>
        </>
    )
}