import React, {FC, useContext, useEffect, useState} from "react";
import { ConfirmModal } from "@/components/ConfirmModal";
import { Box, Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { getPrivateFileDownloadUrl, getPrivateFileUploadUrl } from "@/api/file";
import axios from "axios";
import {StudentHomework} from "@models/homework";
import { Snack, SnackbarContext } from "@/context/SnackbarContext";
import { CourseSelect } from "@/components/CourseSelect";
import { StudentSelect } from "@/components/StudentSelect";
import { getOrders } from "@/api/order";
import { PrivateFile } from "@models/file";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { downloadFile } from "@/helpers/downloadFile";
import {VisuallyHiddenInput} from "@/components/VisuallyHiddenInput";
import {patchStudentHomeworks} from "@/api/homework";

type NewFileRowProps = {
    title: string
    onDelete: () => void
}

const NewFileRow: FC<NewFileRowProps> = (props) => {
    const {
        title,
        onDelete,
    } = props;

    return (
        <Stack spacing={{ xs: 1, sm: 2 }}
               justifyContent="space-between"
               alignItems="center"
               direction="row" useFlexGap flexWrap="wrap">
            <Typography variant="subtitle2">
                {title}
            </Typography>
            <IconButton
                color="error"
                size="small"
                onClick={onDelete}
                sx={{ ml: 0.5 }}
            >
                <DeleteIcon></DeleteIcon>
            </IconButton>
        </Stack>
    );
};

type FileRowProps = {
    file: PrivateFile
    onDelete: () => void
    onPreview: () => void
}

const FileRow: FC<FileRowProps> = (props) => {
    const {
        file,
        onDelete,
        onPreview
    } = props;

    return (
        <Stack spacing={{ xs: 1, sm: 2 }}
               justifyContent="space-between"
               alignItems="center"
               direction="row" useFlexGap flexWrap="wrap">
            <Typography variant="subtitle2">
                {file.filename}
            </Typography>
            <div>
                <IconButton
                    color="secondary"
                    size="small"
                    onClick={onPreview}
                    sx={{ ml: 0.5 }}
                >
                    <RemoveRedEyeIcon></RemoveRedEyeIcon>
                </IconButton>
                <IconButton
                    color="error"
                    size="small"
                    onClick={onDelete}
                    sx={{ ml: 0.5 }}
                >
                    <DeleteIcon></DeleteIcon>
                </IconButton>
            </div>
        </Stack>
    );
};

export type HomeworkFilesModalProps = {
    open: boolean
    onClose: (saved: boolean) => void
    studentHomework: StudentHomework | null
    files: Record<string, PrivateFile>
}

export const StudentHomeworkFilesModal: FC<HomeworkFilesModalProps> = (props) => {

    const {
        open,
        onClose,
        studentHomework,
        files
    } = props;

    const { snack, setSnack } = useContext(SnackbarContext);

    const [initialValues, setInitialValues] = useState({
        courseId: "" as string | number,
        studentId: null as string | null,
        answerFiles: [] as string[],
        markedFiles: [] as string[],
    });
    const [fileLookup, setFileLookup] = useState<Record<string, PrivateFile>>({});

    useEffect(() => {
        if (!open)
            return;
        if (studentHomework)
            setInitialValues({
                courseId: studentHomework.courseId,
                studentId: studentHomework.studentId,
                answerFiles: studentHomework.answerFiles,
                markedFiles: studentHomework.markedFiles,
            });
        else
            setInitialValues({
                courseId: "",
                studentId: null,
                answerFiles: [],
                markedFiles: [],
            });
        formik.resetForm();
    }, [studentHomework, open]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, { setFieldValue }) => {
            if (!studentHomework)
                return

            const uploadFile = async (file: File) => {
                const res = await getPrivateFileUploadUrl(
                    {
                        studentId: values.studentId as string,
                    },
                    {
                        filename: file.name,
                        contentType: file.type,
                    },
                );
                const {
                    fileId,
                    uploadUrl,
                } = res.data;
                await axios.put(uploadUrl.uploadUrl, file, {
                    headers: { "Content-Type": file.type },
                });
                return fileId;
            };

            const newAnswerFileIds: string[] = [];
            const newMarkedFileIds: string[] = [];
            try {
                for (const f of newAnswerFiles) {
                    const fileId = await uploadFile(f.file);
                    newAnswerFileIds.push(fileId);
                }
                for (const f of newMarkedFiles) {
                    const fileId = await uploadFile(f.file);
                    newMarkedFileIds.push(fileId);
                }
            } catch (e) {
                setSnack(Snack.error("上載失敗"));
                return;
            }

            try {
                await patchStudentHomeworks(
                    {
                        id: studentHomework.homeworkId
                    },
                    {
                        answerFiles: [
                            ...values.answerFiles,
                            ...newAnswerFileIds,
                        ],
                        markedFiles: [
                            ...values.markedFiles,
                            ...newMarkedFileIds,
                        ],
                        // "emailSent" | "submitted" | "marked"
                    },
                );
            } catch (e) {
                setSnack(Snack.error("儲存失敗"));
                return;
            }

            setSnack(Snack.success("成功儲存"));
            onClose(true);
        },
        validateOnBlur: false,
        validateOnChange: false,
        // validate: (values: CreateCoursePayload) => {
        // }
    });

    /**
     * New Answer Files
     * */

    const [newAnswerFiles, setNewAnswerFiles] = useState<{ file: File, url: string }[]>([]);

    const handleTempAnswerFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            const file = files[0];
            const url = URL.createObjectURL(file);
            newAnswerFiles.push({ file, url });
            setNewAnswerFiles([...newAnswerFiles]);
        }
    };

    const clickDeleteAnswerFile = (url: string) => {
        formik.setFieldValue("answerFiles", formik.values.answerFiles.filter(f => f !== url));
    };

    const clickDeleteNewAnswerFile = (f: { file: File, url: string }) => {
        setNewAnswerFiles(newAnswerFiles.filter(file => file.url !== f.url));
    };

    /**
     * New Marked Files
     * */

    const [newMarkedFiles, setNewMarkedFiles] = useState<{ file: File, url: string }[]>([]);

    const handleTempMarkedFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            const file = files[0];
            const url = URL.createObjectURL(file);
            newMarkedFiles.push({ file, url });
            setNewMarkedFiles([...newMarkedFiles]);
        }
    };

    const clickDeleteMarkedFile = (url: string) => {
        formik.setFieldValue("markedFiles", formik.values.markedFiles.filter(f => f !== url));
    };

    const clickDeleteNewMarkedFile = (f: { file: File, url: string }) => {
        setNewMarkedFiles(newMarkedFiles.filter(file => file.url !== f.url));
    };

    const downloadPrivateFile = async (f: PrivateFile) => {
        const res = await getPrivateFileDownloadUrl(f);
        downloadFile(res.data.url, f.filename);
    }

    /**
     * Student
     * */

    const [allowedCourseIds, setAllowedCourseIds] = useState<number[]>([]);

    const handleStudentChanged = async (event, value) => {
        // Update formik student id.
        await formik.setFieldValue("studentId", value);
        // Reset course id.
        await formik.setFieldValue("courseId", "");

        const {
            orders,
        } = (await getOrders({
            skip: 0,
            limit: -1,
            count: false,
            studentId: value,
        })).data;
        setAllowedCourseIds(orders.map(o => o.courseId));
    };

    return (
        <ConfirmModal title={"管理檔案"}
                      open={open}
                      onClose={() => onClose(false)}
                      confirmButtonTitle={"儲存"}
                      confirmButtonProps={{
                          disabled: (!formik.dirty && !newAnswerFiles.length && !newMarkedFiles.length) || formik.isSubmitting,
                      }}
                      onSubmit={formik.handleSubmit}>

            {
                studentHomework ?
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">
                            學生ID
                        </Typography>
                        {studentHomework.studentId}
                    </Box>
                    :
                    <StudentSelect
                        sx={{ mt: 2 }}
                        size="small"
                        fullWidth={true}
                        value={formik.values.studentId}
                        onChange={handleStudentChanged}
                        textFieldProps={{
                            name: "studentId",
                            label: "學生",
                            onBlur: formik.handleBlur,
                            error: !!formik.errors.studentId && formik.touched.studentId,
                            helperText: formik.errors.studentId,
                        }}
                    ></StudentSelect>
            }

            {
                studentHomework ?
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">
                            課程ID
                        </Typography>
                        {studentHomework.courseId}
                    </Box>
                    :
                    <CourseSelect
                        allowedCourseIds={allowedCourseIds}
                        sx={{ mt: 2 }}
                        name="courseId"
                        label="課程"
                        size="small"
                        fullWidth={true}
                        value={formik.values.courseId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!formik.errors.courseId && formik.touched.courseId}
                        helperText={formik.errors.courseId}
                    ></CourseSelect>
            }

            <Paper sx={{ p: 4, mt: 2 }}>
                <Typography variant="subtitle2">
                    學生答案檔案
                </Typography>
                {
                    formik.values.answerFiles.map(f => (
                        <FileRow file={files[f]} onPreview={() => downloadPrivateFile(files[f])} onDelete={() => clickDeleteAnswerFile(f)} />
                    ))
                }
                {
                    newAnswerFiles.map(f => (
                        <NewFileRow title={f.file.name} onDelete={() => clickDeleteNewAnswerFile(f)} />
                    ))
                }
                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                    上載檔案
                    <VisuallyHiddenInput type="file" onChange={handleTempAnswerFileChange} />
                </Button>
            </Paper>

            <Paper sx={{ p: 4, mt: 2 }}>
                <Typography variant="subtitle2">
                    已批改檔案
                </Typography>
                {
                    formik.values.markedFiles.map(f => (
                        <FileRow file={files[f]} onPreview={() => downloadPrivateFile(files[f])} onDelete={() => clickDeleteMarkedFile(f)} />
                    ))
                }
                {
                    newMarkedFiles.map(f => (
                        <NewFileRow title={f.file.name} onDelete={() => clickDeleteNewMarkedFile(f)} />
                    ))
                }
                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                    上載檔案
                    <VisuallyHiddenInput type="file" onChange={handleTempMarkedFileChange} />
                </Button>
            </Paper>

        </ConfirmModal>
    );
};
