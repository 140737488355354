import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {Box, Button, FormControlLabel, IconButton, Switch, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PageSizeOptions} from "@/data/options";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import DoneIcon from "@mui/icons-material/Done";
import {getReferralRecords, patchReferralRecord, postReferralRecords} from "@/api/referral";
import {CreateReferralRecordPayload, ReferralRecord} from "@models/referral";
import {useStudentStore} from "@/hooks/useStudentStore";
import {getStudentLookup} from "@/store/slices/student";
import {useAppDispatch} from "@/store";
import {formatFullName} from "@/helpers/format";
import EditIcon from "@mui/icons-material/Edit";
import {useFormik} from "formik";
import {constructInit} from "@/helpers/contructInit";
import {ConfirmModal} from "@/components/ConfirmModal";
import {StudentSelect} from "@/components/StudentSelect";
import {chainRules, requiredInputStringRule} from "common/input-rules";

export default function ReferralRecordsList() {

    const dispatch = useAppDispatch();
    const {snack, setSnack} = useContext(SnackbarContext);
    const {
        studentLookup
    } = useStudentStore()

    const columns: GridColDef<ReferralRecord>[] = [
        {
            field: '_id',
            headerName: '推薦記錄ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: "referrer",
            headerName: "推薦人",
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {studentLookup[row.referrer] ? formatFullName(studentLookup[row.referrer]) : "-"}
                    </Box>
                );
            },
        },
        {
            field: "referee",
            headerName: "被推薦人",
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {studentLookup[row.referee] ? formatFullName(studentLookup[row.referee]) : "-"}
                    </Box>
                );
            },
        },
        {
            field: 'active',
            headerName: '已成功',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    row.active ? <DoneIcon /> : <></>
                )
            }
        },
        {
            field: 'activatedOrderId',
            headerName: '推薦訂單',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Typography
                        variant="h6"
                        color='primary'
                        sx={{ ml: 0.5 }}
                        component={RouterLink}
                        to={`/orders/edit/${row.activatedOrderId}`}
                    >
                        { row.activatedOrderId }
                    </Typography>
                )
            }
        },
        {
            field: 'consumed',
            headerName: '已使用優惠',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    row.consumed ? <DoneIcon /> : <></>
                )
            }
        },
        {
            field: 'consumedOrderId',
            headerName: '使用優惠訂單',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Typography
                        variant="h6"
                        color='primary'
                        sx={{ ml: 0.5 }}
                        component={RouterLink}
                        to={`/orders/edit/${row.consumedOrderId}`}
                    >
                        { row.consumedOrderId }
                    </Typography>
                )
            }
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='primary' size='small'
                            onClick={() => clickEdit(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })

    const query = {
        count: String(paginationModel.pageSize),
        page: String(paginationModel.page + 1),
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.REFERRAL_LIST, query],
        async () => {
            return (await getReferralRecords({
                skip: paginationModel.pageSize * paginationModel.page,
                limit: paginationModel.pageSize,
                count: true
            })).data
        }
    )

    const referralRecordList = useMemo<ReferralRecord[]>(() => {
        // if (!data)
            return []
        // return data.referralRecords.map(u => ({
        //     id: u._id, // Add id for MUI
        //     ...u
        // }))
    }, [data])

    useEffect(() => {
        const allStudentIds = [
            ...referralRecordList.map(r => r.referrer),
            ...referralRecordList.map(r => r.referee),
        ] as string[]
        dispatch(getStudentLookup(allStudentIds.unique()))
    }, [referralRecordList])

    const clickAdd = () => handleOpenModal()

    /***
     * Row actions
     */

    const clickEdit = (row: ReferralRecord) => handleOpenModal(row)

    /***
     * Details Modal
     */

    const emptyReferralRecordRequired: CreateReferralRecordPayload = {
        referrer: "",
        referee: "",
        active: false,
        consumed: false,
    }
    const emptyReferralRecordOptional = {
        activatedOrderId: "",
        consumedOrderId: "",
    }

    const [initialValues, setInitialValues] = useState<CreateReferralRecordPayload>({
        ...emptyReferralRecordRequired
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: async (values) => {
            try {
                if (editingReferralRecord) {
                    await patchReferralRecord({id: editingReferralRecord._id}, values)
                }
                else {
                    await postReferralRecords(values)
                }
                setSnack(Snack.success('成功儲存'))
                handleCloseModal()
                await refetch()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: CreateReferralRecordPayload) => {
            let errors = {
                referrer: chainRules([requiredInputStringRule], values.referrer),
                referee: chainRules([requiredInputStringRule], values.referee)
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })
    const [editingReferralRecord, setEditingReferralRecord] = useState<ReferralRecord | null>(null)
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = useCallback((referralRecord?: ReferralRecord) => {
        setEditingReferralRecord(referralRecord ? referralRecord : null)
        setInitialValues(constructInit(emptyReferralRecordRequired, emptyReferralRecordOptional, referralRecord))
        formik.resetForm()
        setOpenModal(true)
    }, [formik])
    const handleCloseModal = () => setOpenModal(false)
    
    return (
        <>
            <Button variant="contained" onClick={clickAdd}>
                新增
            </Button>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={referralRecordList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            />

            {/* Details Modal */}
            <ConfirmModal title={`${editingReferralRecord && editingReferralRecord._id ? '修改' : '新增'}推薦記錄`}
                          open={openModal}
                          onClose={handleCloseModal}
                          confirmButtonTitle={'儲存'}
                          confirmButtonProps={{
                              disabled: !formik.dirty || formik.isSubmitting
                          }}
                          onSubmit={formik.handleSubmit}>
                <StudentSelect
                    sx={{ mt: 2 }}
                    size="small"
                    fullWidth={true}
                    value={formik.values.referrer}
                    // onChange={handleStudentChanged}
                    textFieldProps={{
                        name: "referrer",
                        label: "推薦人",
                        onBlur: formik.handleBlur,
                        error: !!formik.errors.referrer && formik.touched.referrer,
                        helperText: formik.errors.referrer,
                    }}
                ></StudentSelect>
                <StudentSelect
                    sx={{ mt: 2 }}
                    size="small"
                    fullWidth={true}
                    value={formik.values.referee}
                    // onChange={handleStudentChanged}
                    textFieldProps={{
                        name: "referee",
                        label: "被推薦人",
                        onBlur: formik.handleBlur,
                        error: !!formik.errors.referee && formik.touched.referee,
                        helperText: formik.errors.referee,
                    }}
                ></StudentSelect>
                <Box sx={{ mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch name="active"
                                    checked={formik.values.active}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                        }
                        label="已成功" />
                </Box>
                {/*activatedOrderId*/}

                <Box sx={{ mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch name="consumed"
                                    checked={formik.values.consumed}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                        }
                        label="已使用優惠" />
                </Box>
                {/*consumedOrderId*/}
            </ConfirmModal>
        </>
    )
}