import {Pagination} from "common/models/pagination";
import axios from "axios";
import {
    CreateStudentLeaveRecordPayload,
    CreateStudentPayload,
    Student,
    StudentLeaveRecord,
    UpdateStudentPayload
} from "@models/student";
import {SuccessRes} from "@/model/success-res";
import {Level} from "@models/common";

/***
 * @GET
 * /students
 */

export interface GetStudentsParams {
    keyword?: string,
    level?: Level,
    studentIds?: string[]
}

export interface GetStudentsRes {
    students: Student[],
    count?: number
}

export async function getStudents(params: GetStudentsParams & Partial<Pagination>) {
    return await axios.get<GetStudentsRes>('/students', {
        params: {
            ...params,
            ...(params.studentIds && {
                studentIds: params.studentIds.join(',')
            })
        },
        token: true
    })
}

/***
 * @POST
 * /students
 */

export type PostStudentBody = CreateStudentPayload

export interface PostStudentsRes {
    studentUser: Student
}

export async function postStudents(data: PostStudentBody) {
    return await axios.post<PostStudentsRes>('/students', data, {token: true})
}

/***
 * @PATCH
 * /students/{userId}
 */

export interface PatchStudentPathParams {
    id: string
}

export type PatchStudentData = UpdateStudentPayload

export async function patchStudents(pathParams: PatchStudentPathParams, data: PatchStudentData) {
    return await axios.patch<SuccessRes>(`/students/${pathParams.id}`, data, {token: true})
}

/***
 * @PATCH
 * /students/{userId}/password
 */

export interface PatchStudentPasswordPathParams {
    id: string
}

export interface PatchStudentPasswordData {
    password: string
}

export async function patchStudentPassword(pathParams: PatchStudentPasswordPathParams, data: PatchStudentPasswordData) {
    return await axios.patch<SuccessRes>(`/students/${pathParams.id}/password`, data, {token: true})
}

/***
 * @GET
 * /student-leaves
 */

export interface GetStudentLeaveRecordsRes {
    studentLeaveRecords: StudentLeaveRecord[],
    count?: number
}

export async function getStudentLeaveRecords(params: Partial<Pagination>) {
    return await axios.get<GetStudentLeaveRecordsRes>('/student-leaves', {
        params: {
            ...params,
        },
        token: true
    })
}

/***
 * @POST
 * /students/{userId}/leave
 */

export interface PostStudentLeaveRecordPathParams {
    id: string
}

export type PostStudentLeaveRecordBody = CreateStudentLeaveRecordPayload

export async function postStudentLeaveRecord(pathParams: PostStudentLeaveRecordPathParams, data: PostStudentLeaveRecordBody) {
    return await axios.post<SuccessRes>(`/students/${pathParams.id}/leave`, data, {token: true})
}

