import {
    CourseHomework,
    CourseHomeworkFilter,
    CreateCourseHomeworkPayload, EditCourseHomeworkPayload, EditStudentHomeworkPayload,
    Homework, StudentHomework, StudentHomeworkFilter,
} from "@models/homework";
import axios from "axios";
import {Pagination} from "common/models/pagination";
import {SuccessRes} from "@/model/success-res";

// /***
//  * @GET
//  * /courses/${pathParams.courseId}/homeworks/admin
//  */
//
// export interface GetHomeworksPathParams {
//     courseId: number
// }
//
// export interface GetHomeworksRes {
//     homeworks: Homework[]
//     files: Record<string, PrivateFile>
// }
//
// export async function getHomeworks(pathParams: GetHomeworksPathParams) {
//     return await axios.get<GetHomeworksRes>(`/courses/${pathParams.courseId}/homeworks/admin`, {
//         token: true,
//     });
// }
//
// /***
//  * @PATCH
//  * /courses/{courseId}/students/{studentId}/homeworks
//  */
//
// export interface PatchHomeworkPathParams {
//     courseId: number,
//     studentId: string
// }
//
// export type PatchHomeworkBody = HomeworkEdit
//
// export interface PatchHomeworkRes {
//     homework: Homework
// }
//
// export async function patchHomework(pathParams: PatchHomeworkPathParams, data: PatchHomeworkBody) {
//     return await axios.patch<PatchHomeworkRes>(`/courses/${pathParams.courseId}/students/${pathParams.studentId}/homeworks`, data, {
//         token: true,
//     });
// }
//
// /***
//  * @POST
//  * /courses/{courseId}/students/{studentId}/homeworks/message
//  */
//
// export interface PostHomeworkMessagePathParams {
//     courseId: number,
//     studentId: string
// }
//
// export async function postHomeworkMessage(pathParams: PostHomeworkMessagePathParams) {
//     return await axios.post<SuccessRes>(`/courses/${pathParams.courseId}/students/${pathParams.studentId}/homeworks/message`, undefined, {
//         token: true,
//     });
// }
//
// /***
//  * @POST
//  * /homeworks/message/batch
//  */
//
// export interface PostBatchHomeworkMessageBody {
//     ids: {
//         courseId: number,
//         studentId: string
//     }[]
// }
//
// export async function postBatchHomeworkMessage(data: PostBatchHomeworkMessageBody) {
//     return await axios.post<SuccessRes>(`/homeworks/message/batch`, data, {
//         token: true,
//     });
// }














/***
 * @POST
 * /homeworks
 */

export type PostCourseHomeworkBody = CreateCourseHomeworkPayload

export interface PostCourseHomeworkRes {
    homework: CourseHomework
}

export async function postCourseHomework(data: PostCourseHomeworkBody) {
    return await axios.post<PostCourseHomeworkRes>(`/homeworks`, data, {
        token: true,
    });
}

/***
 * @GET
 * /homeworks
 */

export type GetCourseHomeworksParams = CourseHomeworkFilter;

export interface GetCourseHomeworksRes {
    homeworks: CourseHomework[]
    count: number
}

export async function getCourseHomeworks(params: GetCourseHomeworksParams & Partial<Pagination>) {
    return await axios.get<GetCourseHomeworksRes>(`/homeworks`, {
        params,
        token: true,
    });
}

/***
 * @GET
 * /homeworks/{recordId}
 */

export interface GetCourseHomeworkPathParams {
    id: string
}

export interface GetCourseHomeworkRes {
    homework: CourseHomework
}

export async function getCourseHomework(pathParams: GetCourseHomeworkPathParams) {
    return await axios.get<GetCourseHomeworkRes>(`/homeworks/${pathParams.id}`, {
        token: true
    })
}

/***
 * @PATCH
 * /homeworks/{recordId}
 */

export interface PatchCourseHomeworkPathParams {
    id: number
}

export type PatchCourseHomeworkData = EditCourseHomeworkPayload

export interface PatchCourseHomeworkRes {
    homework: CourseHomework
}

export async function patchCourseHomework(pathParams: PatchCourseHomeworkPathParams, data: PatchCourseHomeworkData) {
    return await axios.patch<PatchCourseHomeworkRes>(`/homeworks/${pathParams.id}`, data, {token: true})
}

/***
 * @GET
 * /student-homeworks
 */

export type GetStudentHomeworksParams = StudentHomeworkFilter;

export interface GetStudentHomeworksRes {
    homeworks: StudentHomework[]
    count: number
}

export async function getStudentHomeworks(params: GetStudentHomeworksParams & Partial<Pagination>) {
    return await axios.get<GetStudentHomeworksRes>(`/student-homeworks`, {
        params,
        token: true,
    });
}

/***
 * @GET
 * /student-homeworks/{recordId}
 */

export interface GetStudentHomeworkPathParams {
    id: string
}

export interface GetStudentHomeworkRes {
    homework: StudentHomework
}

export async function getStudentHomework(pathParams: GetStudentHomeworkPathParams) {
    return await axios.get<GetStudentHomeworkRes>(`/student-homeworks/${pathParams.id}`, {
        token: true
    })
}

/***
 * @PATCH
 * /student-homeworks/{recordId}
 */

export interface PatchStudentHomeworkPathParams {
    id: number
}

export type PatchStudentHomeworkData = EditStudentHomeworkPayload

export interface PatchStudentHomeworkRes {
    homework: StudentHomework
}

export async function patchStudentHomeworks(pathParams: PatchStudentHomeworkPathParams, data: PatchStudentHomeworkData) {
    return await axios.patch<PatchStudentHomeworkRes>(`/student-homeworks/${pathParams.id}`, data, {token: true})
}

/***
 * @POST
 * /student-homeworks/messages
 */

export interface PostStudentHomeworkMessageData {
    ids: number[]
}

export async function postStudentHomeworkMessage(data: PostStudentHomeworkMessageData) {
    return await axios.post<SuccessRes>(`/student-homeworks/messages`, data, {
        token: true,
    });
}