import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {
    Button, FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper, Switch,
    TextField
} from "@mui/material";
import {
    CreateProductPayload
} from "@models/product";
import { useFormik } from "formik";
import {getProduct, patchProduct, postProduct} from "@/api/product";
import {useNavigate, useParams} from "react-router-dom";
import {constructInit} from "@/helpers/contructInit";
import {chainRules, requiredInputNumberRule, requiredInputStringRule} from "common/input-rules";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {VisuallyHiddenInput} from "@/components/VisuallyHiddenInput";
import DeleteIcon from "@mui/icons-material/Delete";
import {uploadToGoogleCloud} from "@/helpers/uploadToGoogleCloud";
import {ProductCategorySelect} from "@/components/ProductCategorySelect";

type ImageListProps = {
    imageUrls: string[]
    removeUrl: (index: number) => void
}

const ImageList: FC<ImageListProps> = (props) =>  {

    const {
        imageUrls,
        removeUrl
    } = props

    return (
        <>
            {
                imageUrls.map((url, idx) => (
                    <Grid item xs={2}>
                        <img
                            style={{objectFit: "cover", width: "100%", aspectRatio: 1}}
                            src={`${url}`}
                            loading="lazy"
                        />
                        <IconButton
                            color='error'
                            size='small'
                            onClick={() => removeUrl(idx)}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </Grid>
                ))
            }
        </>
    )
}

export default function ProductDetail() {

    const navigate = useNavigate();
    const {snack, setSnack} = useContext(SnackbarContext);
    const routeParams = useParams();
    const productId = useMemo<string | null>(() => {
        return routeParams && routeParams.id ? routeParams.id : null
    }, [routeParams]);

    const emptyProductRequired = {
        title: { zh: "", en: "" },
        description: { zh: "", en: "" },
        categories: [] as number[],
        hidden: true,
        price: 0,
        discounted_price: 0,
        stock: 0,
        imageUrls: [],
    }

    const emptyProductOptional = {

    }

    const [initialValues, setInitialValues] = useState({
        ...emptyProductRequired
    });

    useEffect(() => {
        if (productId) {
            getProduct({id: productId}).then(res => {
                setInitialValues(constructInit(emptyProductRequired, emptyProductOptional, res.data.product))
                formik.resetForm()
            })
        }
    }, []);

    const formik = useFormik<CreateProductPayload>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, {setFieldValue}) => {

            const body = {...values}

            try {
                if (imageFiles.length) {
                    const imageUrls = await imageFiles.asyncMap(async f => await uploadToGoogleCloud(f.file))

                    setImageFiles([])
                    setFieldValue("imageUrls", [...body.imageUrls, ...imageUrls])
                    body.imageUrls = [...body.imageUrls, ...imageUrls]
                }
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
                return
            }

            try {
                let pid = 0
                if (productId) {
                    await patchProduct({id: productId}, body)
                }
                else {
                    const res = await postProduct(body)
                    pid = res.data.product._id || pid
                }
                setSnack(Snack.success('成功儲存'))
                if (!productId)
                    navigate(`/products/edit/${pid}`, {
                        replace: true
                    })
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: CreateProductPayload) => {
            let errors = {
                title: {
                    zh: chainRules([requiredInputStringRule], values.title.zh),
                    en: chainRules([requiredInputStringRule], values.title.en),
                },
                description: {
                    zh: chainRules([requiredInputStringRule], values.description.zh),
                    en: chainRules([requiredInputStringRule], values.description.en),
                },
                price: chainRules([requiredInputNumberRule], values.price),
                discounted_price: chainRules([requiredInputNumberRule], values.discounted_price),
                stock: chainRules([requiredInputNumberRule], values.stock),
            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    });

    /**
     * Product Images
     * */

    const [hiddenInputValue, setHiddenInputValue] = useState("");
    const [imageFiles, setImageFiles] = useState<{ file: File, url: string }[]>([]);

    const handleTempImageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            const file = files[0];
            const url = URL.createObjectURL(file);
            imageFiles.push({ file, url });
            setImageFiles([...imageFiles]);
            setHiddenInputValue(""); // Reset hidden input value to trigger on change next time even if same file selected.
            formik.setFieldTouched("imageUrls", true);
        }
    }, [imageFiles]);

    const removeFormikImageUrl = useCallback((index: number) => {
        const urls = [...formik.values.imageUrls]
        urls.splice(index, 1);
        formik.setFieldValue("imageUrls", urls)
    }, [formik]);

    const removeFile = useCallback((index: number) => {
        imageFiles.splice(index, 1);
        setImageFiles([...imageFiles]);
        formik.setFieldTouched("imageUrls", true);
    }, [imageFiles]);

    const categoryChanged = (e) => {
        formik.setFieldValue("categories", e.target.value)
        // formik.handleChange(e)
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Button type="submit"
                        variant="contained"
                        disabled={((!formik.dirty && !formik.touched.imageUrls)) || formik.isSubmitting}>
                    儲存
                </Button>
                <Paper sx={{p: 4, mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Switch name="hidden"
                                            checked={formik.values.hidden}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}/>
                                }
                                label="隱藏產品" />
                        </Grid>
                        <Grid item xs={3}>
                            <ProductCategorySelect name="category"
                                                   label="產品分類"
                                                   size="small"
                                                   fullWidth={true}
                                                   value={formik.values.categories}
                                                   onChange={categoryChanged}
                                                   onBlur={formik.handleBlur}
                                                   error={!!formik.errors.categories && formik.touched.categories}
                                                   helperText={formik.errors.categories}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number"
                                       name="price"
                                       label="價錢"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.price}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.price && formik.touched.price}
                                       helperText={formik.errors.price}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number"
                                       name="discounted_price"
                                       label="售價"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.discounted_price}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.discounted_price && formik.touched.discounted_price}
                                       helperText={formik.errors.discounted_price}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField name="title.zh"
                                       label="中文標題"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.title.zh}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.title?.zh && formik.touched.title?.zh}
                                       helperText={formik.errors.title?.zh}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField name="title.en"
                                       label="英文標題"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.title.en}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.title?.en && formik.touched.title?.en}
                                       helperText={formik.errors.title?.en}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField multiline
                                       rows={6}
                                       name="description.zh"
                                       label="中文描述"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.description.zh}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.description?.zh && formik.touched.description?.zh}
                                       helperText={formik.errors.description?.zh}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField multiline
                                       rows={6}
                                       name="description.en"
                                       label="英文描述"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.description.en}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.description?.en && formik.touched.description?.en}
                                       helperText={formik.errors.description?.en}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number"
                                       name="stock"
                                       label="庫存"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.stock}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.stock && formik.touched.stock}
                                       helperText={formik.errors.stock}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                上載產品照
                                <VisuallyHiddenInput type="file" value={hiddenInputValue} onChange={handleTempImageChange} />
                            </Button>

                            <Grid sx={{mt: 2}} container spacing={2}>
                                <ImageList imageUrls={formik.values.imageUrls} removeUrl={removeFormikImageUrl}/>
                                <ImageList imageUrls={imageFiles.map(f => f.url)} removeUrl={removeFile}/>
                            </Grid>

                        </Grid>

                    </Grid>
                </Paper>
            </form>
        </>
    )
}
