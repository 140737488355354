export enum QUERY_KEY {
    ADMIN_LIST = 'ADMIN_LIST',
    STUDENT_LIST = 'STUDENT_LIST',
    COURSE_LIST = 'COURSE_LIST',
    TUTOR_LIST = 'TUTOR_LIST',
    TUTOR_LEAVE_REQUEST_LIST = 'TUTOR_LEAVE_REQUEST_LIST',
    STUDENT_LEAVE_RECORD_LIST = 'STUDENT_LEAVE_RECORD_LIST',
    ONE_OFF_LESSON_LIST = 'ONE_OFF_LESSON_LIST',
    CLASS_LIST = 'CLASS_LIST',
    ORDER_LIST = 'ORDER_LIST',
    HOMEWORK_LIST = 'HOMEWORK_LIST',
    STUDENT_HOMEWORK_LIST = 'STUDENT_HOMEWORK_LIST',
    ACCOUNT_LIST = 'ACCOUNT_LIST',
    BLOG_LIST = 'BLOG_LIST',
    PRODUCT_LIST = 'PRODUCT_LIST',
    PRODUCT_ORDER_LIST = 'PRODUCT_ORDER_LIST',
    PRODUCT_CATEGORY_LIST = 'PRODUCT_CATEGORY_LIST',
    BANNER_LIST = 'BANNER_LIST',
    DASHBOARD = 'DASHBOARD',
    WHATSAPP_RECORD_LIST = 'WHATSAPP_RECORD_LIST',
    REFERRAL_LIST = 'REFERRAL_LIST'
}
