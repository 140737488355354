import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "@/store";
import * as api from "@/api/student";
import {Student} from "@models/student";

export interface StudentState {
    studentLookup: {[key: string]: Student}
}

const initialState: StudentState = {
    studentLookup: {},
}

const slice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        setStudentLookup(state: StudentState, action: PayloadAction<{[key: string]: Student}>) {
            state.studentLookup = action.payload
        },
        assignStudentLookup(state: StudentState, action: PayloadAction<{[key: string]: Student}>) {
            state.studentLookup = {
                ...state.studentLookup,
                ...action.payload
            }
        },
    }
})

export const { reducer } = slice

export const { setStudentLookup } = slice.actions

export const getStudentLookup = (studentIds: string[]): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            const res = await api.getStudents({studentIds})
            await dispatch(slice.actions.assignStudentLookup(res.data.students.toDict()))
            return res.data
        } catch (err) {
            console.error(err)
        }
    }
