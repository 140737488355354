import { PageSizeOptions } from '@/data/options'
import { QUERY_KEY } from '@/data/query-key'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, {useCallback, useContext, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {Box, Button, IconButton, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import {
  chainRules,
  requiredInputStringRule
} from "common/input-rules";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {ConfirmModal} from "@/components/ConfirmModal";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import {constructInit} from "@/helpers/contructInit";
import {Pagination} from "common/models/pagination";
import {CourseHomework, CreateCourseHomeworkPayload} from "@models/homework";
import {CourseSelect} from "@/components/CourseSelect";
import {
  getCourseHomeworks,
  GetCourseHomeworksParams,
  GetCourseHomeworksRes,
  patchCourseHomework, postCourseHomework
} from "@/api/homework";
import {Course, CourseType, REGULAR_CLASS_MONTH_FORMAT} from "@models/course";

export default function HomeworkList() {

  const {snack, setSnack} = useContext(SnackbarContext);

  const columns: GridColDef<CourseHomework>[] = [
    {
      field: '_id',
      headerName: '功課ID',
      sortable: false,
      flex: 1,
      minWidth: 200
    },
    // courseId: number,
    {
      field: 'month',
      headerName: '月份',
      sortable: false,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'title',
      headerName: '標題',
      sortable: false,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'created',
      headerName: '創建日期',
      sortable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: ({ row }) => dayjs(row.created).format('DD/MM/YYYY')
    },
    {
      field: 'action',
      headerName: '行動',
      sortable: false,
      minWidth: 120,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <IconButton
              color='primary' size='small'
              onClick={() => clickEdit(row)}
              sx={{ ml: 0.5 }}
            >
              <EditIcon></EditIcon>
            </IconButton>
          </Box>
        )
      }
    }
  ]


  const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
    page: 0,
    pageSize: 50
  })
  const [keyword, setKeyword] = useState<string | null>(null)

  const [query, setQuery] = useState<GetCourseHomeworksParams & Partial<Pagination>>({
    skip: paginationModel.pageSize * paginationModel.page,
    limit: paginationModel.pageSize,
    count: true
  })

  const { data, refetch, isLoading } = useQuery(
    [QUERY_KEY.HOMEWORK_LIST, query],
    async () => {
      return (await getCourseHomeworks(query)).data as GetCourseHomeworksRes
    }
  )

  const homeworkList = useMemo(() => {
    if (!data)
      return []
    return data.homeworks.map(u => ({
      id: u._id?.toString() || "", // Add id for MUI
      ...u
    }))
  }, [data])

  const clickAdd = () => handleOpenModal()

  /***
   * Row actions
   */

  const clickEdit = (row: CourseHomework) => handleOpenModal(JSON.parse(JSON.stringify(row)))

  /***
   * Details Modal
   */

  const [monthOptions, setMonthOptions] = useState<string[]>([])
  const [course, setCourse] = useState<Course | null>(null)

  const emptyCourseHomeworkRequired: CreateCourseHomeworkPayload = {
    courseId: -1,
    title: "",
    month: ""
  }
  const emptyCourseHomeworkOptional = {

  }

  const [initialValues, setInitialValues] = useState<CreateCourseHomeworkPayload>({
    ...emptyCourseHomeworkRequired
  });

  const formik = useFormik<CreateCourseHomeworkPayload>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      try {
        const body = {...values}
        if (course && course.type === CourseType.OneOff) {
          delete body["month"]
        }

        if (editingCourseHomework) {
          await patchCourseHomework({id: editingCourseHomework._id || -1}, body)
        }
        else {
          await postCourseHomework(body)
        }
        setSnack(Snack.success('成功儲存'))
        handleCloseModal()
        await refetch()
      }
      catch (e) {
        setSnack(Snack.error('儲存失敗'))
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      let errors = {
        title: chainRules([requiredInputStringRule], values.title),
      }
      Object.trimLeaves(errors, [true, {}]);
      return errors;
    }
  })
  const [editingCourseHomework, setEditingCourseHomework] = useState<CourseHomework | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const handleOpenModal = useCallback((homework?: CourseHomework) => {
    setEditingCourseHomework(homework ? homework : null)
    setInitialValues(constructInit(emptyCourseHomeworkRequired, emptyCourseHomeworkOptional, homework))
    formik.resetForm()
    setMonthOptions([])
    setOpenModal(true)
  }, [formik])
  const handleCloseModal = () => setOpenModal(false)

  const courseChanged = async (course?: Course) => {

    // Reset data options.
    setCourse(course || null)
    setMonthOptions([])

    await formik.setFieldValue("month", "")

    if (!course)
      return
    if (course.type === CourseType.Regular) {
      const thisMonth = dayjs().format(REGULAR_CLASS_MONTH_FORMAT)
      const nextMonth = dayjs().startOf("month").add(1, "month").format(REGULAR_CLASS_MONTH_FORMAT)
      setMonthOptions([
        thisMonth,
        nextMonth
      ])
    }
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={clickAdd}
      >
        新增
      </Button>

      <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ mb: 2 }}
        >
          篩選
        </Typography>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <CourseSelect
            // allowedCourseIds={allowedCourseIds}
            sx={{ mt: 2 }}
            name="courseId"
            label="課程"
            size="small"
            fullWidth={true}
            value={query.courseId}
            onChange={(e) => setQuery({...query, courseId: Number(e.target.value)})}
          ></CourseSelect>
        </Box>
      </Paper>

      <DataGrid
        sx={{
          mt: 2,
          backgroundColor: 'white'
        }}
        loading={isLoading}
        rows={homeworkList}
        columns={columns}

        pagination
        paginationMode='server'
        paginationModel={paginationModel}
        rowCount={data?.count || 0}
        pageSizeOptions={PageSizeOptions}
        onPaginationModelChange={setPaginationModel}

        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
      />

      {/* Details Modal */}
      <ConfirmModal title={`${editingCourseHomework ? '修改' : '新增'}功課`}
                    open={openModal}
                    onClose={handleCloseModal}
                    confirmButtonTitle={'儲存'}
                    confirmButtonProps={{
                      disabled: !formik.dirty || formik.isSubmitting
                    }}
                    onSubmit={formik.handleSubmit}>
        <TextField sx={{mt: 2}}
                   id="title"
                   label="標題"
                   variant="outlined"
                   size="small"
                   fullWidth={true}
                   value={formik.values.title}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   error={!!formik.errors.title && formik.touched.title}
                   helperText={formik.errors.title}
        />
        <CourseSelect
          // allowedCourseIds={allowedCourseIds}
          sx={{ mt: 2 }}
          name="courseId"
          label="課程"
          size="small"
          fullWidth={true}
          value={formik.values.courseId}
          onChange={formik.handleChange}
          onCourseChanged={courseChanged}
          onBlur={formik.handleBlur}
          error={!!formik.errors.courseId && formik.touched.courseId}
          helperText={formik.errors.courseId}
        ></CourseSelect>
        {
          !!monthOptions.length && (
            <TextField
              sx={{ mt: 2 }}
              name="month"
              label="功課月份"
              size="small"
              fullWidth={true}
              value={formik.values.month}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.errors.month && formik.touched.month}
              helperText={formik.errors.month}
              select>
              {
                monthOptions.map((m, idx) => (
                  <MenuItem key={m} value={m}>
                    {m}
                  </MenuItem>
                ))
              }
            </TextField>
          )
        }
      </ConfirmModal>
    </>
  )
}
