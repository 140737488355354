import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "@/store";
import * as api from "@/api/admin";

export interface AdminState {
  adminNamesLookup: {[key: string]: string}
}

const initialState: AdminState = {
  adminNamesLookup: {},
}

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminNamesLookup(state: AdminState, action: PayloadAction<{[key: string]: string}>) {
      state.adminNamesLookup = action.payload
    },
    assignAdminNamesLookup(state: AdminState, action: PayloadAction<{[key: string]: string}>) {
      state.adminNamesLookup = {
        ...state.adminNamesLookup,
        ...action.payload
      }
    },
  }
})

export const { reducer } = slice

export const { setAdminNamesLookup } = slice.actions

export const getAdminNamesLookup = (userIds: string[]): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    try {
      if (!userIds.length)
        return null
      const res = await api.getAdminNames({userIds})
      await dispatch(slice.actions.assignAdminNamesLookup(res.data))
      return res.data
    } catch (err) {
      console.error(err)
    }
  }
