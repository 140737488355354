import {
    Alert,
    AppBar,
    Box, Button, Card, CardActions, CardContent,
    Divider,
    Drawer, FormControl, FormHelperText,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, ListSubheader, Snackbar, TextField, Toolbar, Typography
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ArticleIcon from '@mui/icons-material/Article';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PaidIcon from "@mui/icons-material/Paid";
import BrushIcon from "@mui/icons-material/Brush";
import DescriptionIcon from "@mui/icons-material/Description";
import CategoryIcon from "@mui/icons-material/Category";
import CalculateIcon from "@mui/icons-material/Calculate";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import MessageIcon from "@mui/icons-material/Message";
import SpeedIcon from "@mui/icons-material/Speed";
import AssistantIcon from "@mui/icons-material/Assistant";
import RecommendIcon from "@mui/icons-material/Recommend";
import {Link, Outlet, useLocation} from 'react-router-dom';
import React, {useState, MouseEvent, KeyboardEvent, useEffect, ReactNode} from 'react';
import {chainRules, formatEmailRule, requiredInputStringRule} from "common/input-rules";
import {useFormik} from 'formik';
import {postAdminsLogin} from "@/api/admin";
import dayjs from "dayjs";
import {CredentialData} from "common/models/credential-data";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {getAdminSelf} from "@/store/slices/auth";
import {useAppDispatch} from "@/store";
import {AxiosError} from "axios";
import {AuthError} from "@errors/auth";
import {Confirm, ConfirmContext} from "@/context/ConfirmContext";
import {ConfirmModal} from "@/components/ConfirmModal";

export default function Root() {

    /**
     * Drawer
     * */

    const items = [
        {
            title: '萊鑽管理員網站',
            path: '/',
            icon: <AdminPanelSettingsIcon />
        },

        {
            title: '系統',
            subItems: [
                {
                    title: '假期列表',
                    path: '/holidays',
                    icon: <CalendarMonthIcon />
                },
                {
                    title: '訊息記錄列表',
                    path: '/whatsapp-records',
                    icon: <MessageIcon />
                },
                {
                    title: '統計',
                    path: '/dashboard',
                    icon: <SpeedIcon />
                },
                {
                    title: '課程建議',
                    path: '/recommendation',
                    icon: <AssistantIcon />
                },
            ]
        },

        {
            title: '課程',
            subItems: [
                {
                    title: '課程列表',
                    path: '/courses',
                    icon: <MenuBookIcon />
                },
                {
                    title: '訂單列表',
                    path: '/orders',
                    icon: <ReceiptLongIcon />
                },
                {
                    title: '功課列表',
                    path: '/homeworks',
                    icon: <ArticleIcon />
                },
                {
                    title: '功課提交記錄列表',
                    path: '/student-homeworks',
                    icon: <ArticleIcon />
                },
                {
                    title: '推薦課程記錄列表',
                    path: '/referral-records',
                    icon: <RecommendIcon />
                },
            ]
        },

        {
            title: '内容',
            subItems: [
                {
                    title: '橫幅列表',
                    path: '/banners',
                    icon: <DashboardIcon />
                },
                {
                    title: '文章列表',
                    path: '/blogs',
                    icon: <DescriptionIcon />
                },
            ]
        },

        {
            title: '會計',
            subItems: [
                {
                    title: '支出列表',
                    path: '/expenses',
                    icon: <PaidIcon />
                },
                {
                    title: '收入列表',
                    path: '/sales',
                    icon: <PaidIcon />
                },
                {
                    title: '會計列表',
                    path: '/accounting',
                    icon: <CalculateIcon />
                },
                {
                    title: '未付款學生列表',
                    path: '/unpaid-students',
                    icon: <MoneyOffIcon />
                },
            ]
        },

        {
            title: '用戶',
            subItems: [
                {
                    title: '學生列表',
                    path: '/users',
                    icon: <PersonIcon />
                },
                {
                    title: '導師列表',
                    path: '/tutors',
                    icon: <AssignmentIndIcon />
                },
                {
                    title: '管理員列表',
                    path: '/admins',
                    icon: <SupportAgentIcon />
                },
                {
                    title: '學生請假申請列表',
                    path: '/student-leave-requests',
                    icon: <EventBusyIcon />
                },
                {
                    title: '導師請假申請列表',
                    path: '/tutor-leave-requests',
                    icon: <EventBusyIcon />
                },
            ]
        },

        {
            title: '產品',
            subItems: [
                {
                    title: '產品列表',
                    path: '/products',
                    icon: <BrushIcon />
                },
                {
                    title: '產品訂單列表',
                    path: '/product-orders',
                    icon: <DashboardIcon />
                },
                {
                    title: '產品分類表',
                    path: '/product-categories',
                    icon: <CategoryIcon />
                },
            ]
        },

    ]

    const flattenedItems = items.map(item => (item.subItems ? [{title: item.title}, ...item.subItems] : [item]) as {title: string, path?: string, icon?: ReactNode}[] ).flat();

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) =>
        (event: KeyboardEvent | MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as KeyboardEvent).key === 'Tab' ||
                    (event as KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setDrawerOpen(open)
        }

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const location = useLocation();

    const handleListItemClick = (
        event: MouseEvent,
        index: number,
    ) => {
        setDrawerOpen(false);
        setSelectedIndex(index);
    };

    const handleLogoutClick = (event: MouseEvent) => {
        const d: CredentialData = {
            accessExpiresAt: 0,
            accessToken: "",
            refreshExpiresAt: 0,
            refreshToken: "",
            userId: ""
        }
        localStorage.setCredentialData(process.env.REACT_APP_CREDENTIAL_KEY || "", d)
        setIsLoggedIn(false)
        setDrawerOpen(false);
        setSelectedIndex(0);
    }

    /**
     * Init
     * */

    const dispatch = useAppDispatch()
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        // Check login status.
        const credential = localStorage.getCredentialData(process.env.REACT_APP_CREDENTIAL_KEY || "");
        setIsLoggedIn(credential && credential.refreshExpiresAt >= Date.now())

        // Set drawer selection.
        const index = items.findIndex(i => i.path === location.pathname)
        setSelectedIndex(index)
    }, [])

    useEffect(() => {
        if (isLoggedIn) {
            // Get own profile.
            dispatch(getAdminSelf())
        }
        else {

        }
    }, [isLoggedIn])

    /**
     * Login form
     * */

    const [helperText, setHelperText] = useState("")
    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        onSubmit: async (values) => {
            try {
                const res = await postAdminsLogin({
                    email: values.email,
                    password: values.password
                });
                const d: CredentialData = {
                    accessExpiresAt: dayjs().add(res.data.accessExpiresIn, "seconds").valueOf(),
                    accessToken: res.data.accessToken,
                    refreshExpiresAt: dayjs().add(res.data.refreshExpiresIn, "seconds").valueOf(),
                    refreshToken: res.data.refreshToken,
                    userId: res.data.userId,
                }
                localStorage.setCredentialData(process.env.REACT_APP_CREDENTIAL_KEY || "", d)
                setIsLoggedIn(true)
                formik.resetForm()
            }
            catch (e) {
                if (e instanceof AxiosError && e.response) {
                    if (e.response.data.message === AuthError.UserNotFound || e.response.data.message === AuthError.InvalidPassword) {
                        setHelperText('用戶不存在或密碼錯誤')
                    }
                }

            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: { email: string; password: string; }) => {
            let errors = {
                email: chainRules([requiredInputStringRule, formatEmailRule], values.email),
                password: requiredInputStringRule(values.password),
            };
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })

    /***
     * Root snackbar
     */
    const [snack, setSnack] = useState(new Snack({open: false}));
    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack(new Snack({color: snack.color, open:false}));
    };

    /***
     * Root confirmation
     */
    const [confirmation, setConfirmation] = useState(new Confirm({
        title: '',
        open: false
    }));
    const handleConfirmClose = () => {
        if (confirmation.onClose) {
            confirmation.onClose()
        }
        setConfirmation(new Confirm({
            title: '',
            open: false,
        }));
    };

    return (
        <ConfirmContext.Provider value={{confirmation, setConfirmation}}>
            <SnackbarContext.Provider value={{snack, setSnack}}>
                <AppBar component='nav'>
                    <Toolbar>

                        {
                            isLoggedIn &&
                            (
                                <IconButton
                                    color='inherit'
                                    aria-label='open drawer'
                                    edge='start'
                                    onClick={toggleDrawer(true)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )
                        }


                        <Typography
                            variant='h6'
                            component='div'
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            {flattenedItems[selectedIndex] ? flattenedItems[selectedIndex].title : ''}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Box component='nav'>
                    <Drawer
                        anchor={'left'}
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                    >
                        <div id='sidebar'>
                            <Typography
                                variant='h6'
                                sx={{ padding: 2 }}
                                component='div'
                            >
                                萊鑽管理員網站
                            </Typography>

                            {/*<Button variant="contained"*/}
                            {/*        sx={{ margin: 2 }}*/}
                            {/*        component={RouterLink}*/}
                            {/*        to="/">*/}
                            {/*    萊鑽管理員網站*/}
                            {/*</Button>*/}


                            <Divider />
                            <List sx={{ minWidth: 256 }}>
                                {flattenedItems.map((item, index) => (
                                    item.path && item.icon ?
                                        <ListItem key={item.title}
                                                  disablePadding>
                                            <ListItemButton selected={selectedIndex === index}
                                                            component={Link}
                                                            to={item.path}
                                                            onClick={(event) => handleListItemClick(event, index)}>
                                                <ListItemIcon>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.title} />
                                            </ListItemButton>
                                        </ListItem>
                                    :
                                        <ListSubheader component="div">
                                            {item.title}
                                        </ListSubheader>
                                ))}

                                <ListItem disablePadding>
                                    {/*component={Link}*/}
                                    {/*to={item.path}*/}
                                    <ListItemButton onClick={handleLogoutClick} component={Link} to={'/'}>
                                        <ListItemIcon>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'登出'} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </div>
                    </Drawer>
                </Box>

                <Box component='main'
                     id='detail'
                     sx={{
                         backgroundColor: 'grey.200',
                         padding: 4,
                         flex: 1
                     }}>
                    <Toolbar />

                    {
                        !isLoggedIn ?
                            (
                                <Card sx={{ minWidth: 275, maxWidth: 450 }}>
                                    <form noValidate
                                          onSubmit={formik.handleSubmit}>
                                        <CardContent>
                                            <TextField type="email"
                                                       id="email"
                                                       label="電郵"
                                                       variant="outlined"
                                                       size="small"
                                                       fullWidth={true}
                                                       value={formik.values.email}
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       error={!!formik.errors.email && formik.touched.email}
                                                       helperText={formik.errors.email}
                                            />
                                            <TextField sx={{mt: 2}}
                                                       type="password"
                                                       id="password"
                                                       label="密碼"
                                                       variant="outlined"
                                                       size="small"
                                                       fullWidth={true}
                                                       value={formik.values.password}
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       error={!!formik.errors.password && formik.touched.password}
                                                       helperText={formik.errors.password}
                                            />
                                            {
                                                helperText && (
                                                    <FormControl error={true}>
                                                        <FormHelperText>{helperText}</FormHelperText>
                                                    </FormControl>
                                                )
                                            }
                                        </CardContent>
                                        <CardActions>
                                            <Button type="submit"
                                                    variant="contained"
                                                    disabled={!formik.dirty || formik.isSubmitting}>
                                                登入
                                            </Button>
                                        </CardActions>
                                    </form>
                                </Card>
                            )
                            :
                            (
                                <Outlet />
                            )
                    }
                </Box>

                <Snackbar open={snack.open} autoHideDuration={snack.autoHideDuration} onClose={handleSnackbarClose}>
                    <Alert severity={snack.color}>
                        {snack.message || ''}
                    </Alert>
                </Snackbar>

                <ConfirmModal title={confirmation.title}
                              open={confirmation.open}
                              onClose={handleConfirmClose}
                              confirmButtonTitle={confirmation.confirmButtonTitle}
                              confirmButtonProps={confirmation.confirmButtonProps}
                              onSubmit={confirmation.onSubmit}/>

            </SnackbarContext.Provider>
        </ConfirmContext.Provider>
    );
}
