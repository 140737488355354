import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authReducer } from './slices/auth'
import { reducer as tutorReducer } from './slices/tutor'
import { reducer as messageTemplateReducer } from './slices/whatsapp-template'
import { reducer as studentReducer } from './slices/student'
import { reducer as adminReducer } from './slices/admin'

export const rootReducer = combineReducers({
    auth: authReducer,
    tutor: tutorReducer,
    messageTemplate: messageTemplateReducer,
    student: studentReducer,
    admin: adminReducer
})
