import {CreateReferralRecordPayload, UpdateReferralRecordPayload, ReferralRecord} from "@models/referral";
import {Pagination} from "common/models/pagination";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";

/***
 * @POST
 * /referral-records
 */

export type PostReferralRecordBody = CreateReferralRecordPayload

export interface PostReferralRecordsRes {
    referralRecord: ReferralRecord
}

export async function postReferralRecords(data: PostReferralRecordBody) {
    return await axios.post<PostReferralRecordsRes>('/referral-records', data, {token: true})
}

/***
 * @PATCH
 * /referral-records/{recordId}
 */

export interface PatchReferralRecordPathParams {
    id: number
}

export type PatchReferralRecordData = UpdateReferralRecordPayload

export async function patchReferralRecord(pathParams: PatchReferralRecordPathParams, data: PatchReferralRecordData) {
    return await axios.patch<SuccessRes>(`/referral-records/${pathParams.id}`, data, {token: true})
}

/***
 * @GET
 * /referral-records
 */

export interface GetReferralRecordsRes {
    referralRecords: ReferralRecord[],
    count?: number
}

export async function getReferralRecords(params: Partial<Pagination>) {
    return await axios.get<GetReferralRecordsRes>('/referral-records', {
        params,
        token: true
    })
}

/***
 * @GET
 * /referral-records/{recordId}
 */

export interface GetReferralRecordPathParams {
    id: string
}

export interface GetReferralRecordRes {
    referralRecord: ReferralRecord
}

export async function getReferralRecord(pathParams: GetReferralRecordPathParams) {
    return await axios.get<GetReferralRecordRes>(`/referral-records/${pathParams.id}`, {
        token: true
    })
}


