import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {SnackbarContext} from "@/context/SnackbarContext";
import {Box, Button, IconButton} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PageSizeOptions} from "@/data/options";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {Student} from "@models/student";
import {getStudents} from "@/api/student";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {CsvExportButton} from "@/components/CsvExportButton";
import { ProductOrder } from "@models/product-order";
import {getProductOrders} from "@/api/product-order";
import {orderStatusUI} from "@models/order";

export default function ProductOrderList() {

    const {snack, setSnack} = useContext(SnackbarContext);

    const columns: GridColDef<ProductOrder>[] = [
        {
            field: '_id',
            headerName: '訂單ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'productItems',
            headerName: '訂單内容',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { row.productItems.map(item => (
                            <Box>
                                { item.product.title.zh }
                            </Box>
                        )) }
                    </Box>
                )
            }
        },
        {
            field: 'status',
            headerName: '狀態',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => orderStatusUI[row.status].title
        },
        {
            field: 'firstName',
            headerName: '學生名字',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => (studentLookup[row.studentId] ? studentLookup[row.studentId].firstName : "-")
        },
        {
            field: 'lastName',
            headerName: '學生姓氏',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => (studentLookup[row.studentId] ? studentLookup[row.studentId].lastName : "-")
        },
        {
            field: 'createdAt',
            headerName: '創建日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => dayjs(row.createdAt).format('DD/MM/YYYY')
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='primary'
                            size='small'
                            sx={{ ml: 0.5 }}
                            component={RouterLink}
                            to={`/product-orders/edit/${row._id}`}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })
    const [keyword, setKeyword] = useState<string | null>(null)

    const query = {
        skip: paginationModel.pageSize * paginationModel.page,
        limit: paginationModel.pageSize,
        count: true,
        ...(!!keyword && {keyword}),
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.PRODUCT_ORDER_LIST, query],
        async () => {
            return (await getProductOrders(query)).data
        }
    )

    const productOrderList = useMemo<ProductOrder[]>(() => {
        if (!data)
            return []
        return data.productOrders.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
    }, [data])

    const [studentLookup, setStudentLookup] = useState<{[key: string]: Student}>({})
    useEffect(() => {
        getStudents({
            studentIds: [...new Set(productOrderList.map(o => o.studentId))]
        })
            .then(res => {
                const lookup = res.data.students.reduce((l, student) => {
                    l[student._id] = student
                    return l
                }, {} as {[key: string]: Student})
                setStudentLookup(lookup)
            })
            .catch(e => {

            })
    }, [productOrderList]);

    /***
     * Row actions
     */

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRows(rowSelectionModel);
    };

    return (
        <>
            <Button variant="contained" component={RouterLink} to="/product-orders/add">
                新增
            </Button>

            <CsvExportButton sx={{ml: 2}}
                             disabled={selectedRows.length === 0}
                             fileName={"productOrders"}
                             header={[]}
                             rows={productOrderList.filter(o => selectedRows.includes(o._id)).map(o => ([
                                 o._id,
                                 o.productItems.map(item => item.product._id?.toString() || "").join(","),
                                 o.price.toString(),
                                 studentLookup[o.studentId] ? studentLookup[o.studentId].firstName : "-",
                                 studentLookup[o.studentId] ? studentLookup[o.studentId].lastName : "-",
                                 dayjs(o.createdAt).format('DD/MM/YYYY')
                             ]))}/>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={productOrderList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                checkboxSelection
                onRowSelectionModelChange={handleRowSelectionChange}
            />
        </>
    )
}