import {
    CreateMessageRecordPayload,
    EditMessageRecordPayload, MessageTemplateDetailInfo,
    MessageTemplateListInfo,
    WhatsappRecord
} from "@models/whatsapp";
import {SuccessRes} from "@/model/success-res";
import axios from "axios";
import {Pagination} from "common/models/pagination";

/***
 * @POST
 * /message/whatsapp-records
 */

export type PostWhatsappRecordBody = CreateMessageRecordPayload

export type PostWhatsappRecordRes = SuccessRes

export async function postWhatsappRecord(data: PostWhatsappRecordBody) {
    return await axios.post<PostWhatsappRecordRes>('/message/whatsapp-records', data, {token: true})
}

/***
 * @PATCH
 * /message/whatsapp-records/{recordId}
 */

export interface PatchWhatsappRecordPathParams {
    recordId: number
}

export type PatchWhatsappRecordBody = EditMessageRecordPayload

export type PatchWhatsappRecordRes = SuccessRes

export async function patchWhatsappRecord(pathParams: PatchWhatsappRecordPathParams, data: PatchWhatsappRecordBody) {
    return await axios.patch<PatchWhatsappRecordRes>(`/message/whatsapp-records/${pathParams.recordId}`, data, {token: true})
}

/***
 * @GET
 * /message/whatsapp-records
 */

export interface GetWhatsappRecordsRes {
    whatsappRecords: WhatsappRecord[],
    count?: number
}

export async function getWhatsappRecords(params: Partial<Pagination>) {
    return await axios.get<GetWhatsappRecordsRes>('/message/whatsapp-records', {
        params,
        token: true
    })
}

/***
 * @GET
 * /message/whatsapp-templates
 */

export interface GetWhatsappTemplatesRes {
    whatsappTemplates: MessageTemplateListInfo[]
}

export async function getWhatsappTemplates() {
    return await axios.get<GetWhatsappTemplatesRes>('/message/whatsapp-templates', {
        token: true
    })
}

/***
 * @GET
 * /message/whatsapp-templates/{templateId}
 */

export interface GetWhatsappTemplatePathParams {
    templateId: string
}

export interface GetWhatsappTemplateRes {
    whatsappTemplate: MessageTemplateDetailInfo
}

export async function getWhatsappTemplate(pathParams: GetWhatsappTemplatePathParams) {
    return await axios.get<GetWhatsappTemplateRes>(`/message/whatsapp-templates/${pathParams.templateId}`, {
        token: true
    })
}