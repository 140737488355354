import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {
    Box,
    Button,
    IconButton,
    MenuItem,
    TextField
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PageSizeOptions} from "@/data/options";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {getStudentLeaveRecords, getStudents, postStudentLeaveRecord} from "@/api/student";
import {CreateStudentLeaveRecordPayload, Student, StudentLeaveRecord} from "@models/student";
import {useAppDispatch} from "@/store";
import DoneIcon from "@mui/icons-material/Done";
import {constructInit} from "@/helpers/contructInit";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {useFormik} from "formik";
import {ConfirmModal} from "@/components/ConfirmModal";
import {StudentSelect} from "@/components/StudentSelect";
import {CourseSelect} from "@/components/CourseSelect";
import { getOrders } from "@/api/order";
import {
    Course,
    CourseType
} from "@models/course";
import {OrderStatus, RegularOrderTimeslot} from "@models/order";

export default function StudentLeaveRecordList() {

    const {snack, setSnack} = useContext(SnackbarContext);

    const columns: GridColDef<StudentLeaveRecord>[] = [
        {
            field: '_id',
            headerName: '請假記錄ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'firstName',
            headerName: '學生名字',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { studentLookup[row.studentId] ? studentLookup[row.studentId].firstName : "-" }
                    </Box>
                )
            }
        },
        {
            field: 'lastName',
            headerName: '學生姓氏',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { studentLookup[row.studentId] ? studentLookup[row.studentId].lastName : "-" }
                    </Box>
                )
            }
        },
        {
            field: 'creditUsed',
            headerName: '已使用餘額',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    row.creditUsed ? <DoneIcon /> : <></>
                )
            }
        },
        {
            field: 'date',
            headerName: '請假日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { dayjs(row.date).format('DD/MM/YYYY') }
                    </Box>
                )
            }
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='primary'
                            size='small'
                            sx={{ ml: 0.5 }}
                            component={RouterLink}
                            to={`/product-orders/edit/${row._id}`}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })

    const query = {
        skip: paginationModel.pageSize * paginationModel.page,
        limit: paginationModel.pageSize,
        count: true,
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.STUDENT_LEAVE_RECORD_LIST, query],
        async () => {
            return (await getStudentLeaveRecords(query)).data
        }
    )

    const studentLeaveRecordList = useMemo<StudentLeaveRecord[]>(() => {
        if (!data)
            return []
        return data.studentLeaveRecords.map(r => ({
            id: r._id, // Add id for MUI
            ...r
        }))
    }, [data])

    const clickAdd = () => handleOpenModal()

    /**
     * Init
     * */

    const dispatch = useAppDispatch()

    // /***
    //  * Row actions
    //  */
    //
    // const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    //
    // const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
    //     setSelectedRows(rowSelectionModel);
    // };

    /***
     * Details Modal
     */

    const emptyStudentLeaveRecordRequired: CreateStudentLeaveRecordPayload = {
        courseId: -1,
        classId: -1,
        month: "",
        date: "",
    }
    const emptyStudentLeaveRecordOptional = {

    }

    const [initialValues, setInitialValues] = useState<CreateStudentLeaveRecordPayload & {studentId: string}>({
        studentId: "",
        ...emptyStudentLeaveRecordRequired
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: async (values) => {
            try {
                const {
                    studentId,
                    ...payload
                } = values
                await postStudentLeaveRecord({id: studentId}, payload)
                setSnack(Snack.success('成功儲存'))
                handleCloseModal()
                await refetch()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: CreateStudentLeaveRecordPayload) => {
            let errors = {
                // courseId: -1,
                // classId: -1,
                month: chainRules([requiredInputStringRule], values.month),
                date: chainRules([requiredInputStringRule], values.date),
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })
    const [editingStudentLeaveRecord, setEditingStudentLeaveRecord] = useState<StudentLeaveRecord | null>(null)
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = useCallback((expense?: StudentLeaveRecord) => {
        setEditingStudentLeaveRecord(expense ? expense : null)
        setInitialValues(constructInit(emptyStudentLeaveRecordRequired, emptyStudentLeaveRecordOptional, expense))
        formik.resetForm()
        setOpenModal(true)
    }, [formik])
    const handleCloseModal = () => setOpenModal(false)

    /**
     * Student
     * */

    const [studentLookup, setStudentLookup] = useState<{[key: string]: Student}>({})
    useEffect(() => {
        getStudents({
            studentIds: [...new Set(studentLeaveRecordList.map(r => r.studentId))]
        })
            .then(res => {
                const lookup = res.data.students.reduce((l, student) => {
                    l[student._id] = student
                    return l
                }, {} as {[key: string]: Student})
                setStudentLookup(lookup)
            })
            .catch(e => {

            })
    }, [studentLeaveRecordList]);

    const [allowedCourseIds, setAllowedCourseIds] = useState<number[]>([]);
    const [timeslotLookup, setTimeslotLookup] = useState<{[key: string]: {[key: number]: RegularOrderTimeslot[]}}>({})

    const courseChanged = async (course?: Course) => {

        await formik.setFieldValue("classId", null)
        await formik.setFieldValue("month", "")
        await formik.setFieldValue("date", "")

        if (!course)
            return

        // const thisMonth = dayjs().format("YYYY/MM")
        // const nextMonth = dayjs().startOf("month").add(1, "month").format("YYYY/MM")

        // const classesRes = await getRegularClasses({ courseId: course._id }, {
        //     fromMonth: thisMonth,
        //     toMonth: nextMonth,
        // })
        // const instances = classesRes.data.classes
        // const lookup = instances.reduce((defLookup, instance) => ({
        //     ...defLookup,
        //     [instance.classId]: instance,
        // }), {} as {[key:number]: RegularClassDef})

        // await formik.setFieldValue("timeslots", [])
        // await formik.setFieldValue("classId", null)
        // setClasses(instances)
        // setClassDefs(Object.values(lookup))
    }

    const handleStudentChanged = async (event, value) => {
        // Update formik student id.
        await formik.setFieldValue("studentId", value);
        // Reset course id.
        await formik.setFieldValue("courseId", "");

        const {
            orders,
        } = (await getOrders({
            skip: 0,
            limit: -1,
            count: false,
            courseType: CourseType.Regular,
            studentId: value,
            status: OrderStatus.Paid
        })).data;
        setAllowedCourseIds(orders.map(o => o.courseId));

        const l = orders.reduce((lookup, o) => {
            if (!lookup[o.courseId])
                lookup[o.courseId] = {}
            const classId = o.classId || -1
            if (!lookup[o.courseId][classId])
                lookup[o.courseId][classId] = []
            lookup[o.courseId] = {
                ...lookup[o.courseId],
                [classId]: [...lookup[o.courseId][classId], ...(o.timeslots as RegularOrderTimeslot[])]
            }
            return lookup
        }, {} as {[key: string]: {[key: number]: RegularOrderTimeslot[]}});
        console.log(l);
        setTimeslotLookup(l);
    };

    const handleDateChanged = async (event, value) => {
        // Update formik date.
        await formik.setFieldValue("date", value);

        // Set corresponding month as well.
        const timeslots = timeslotLookup[formik.values.courseId][formik.values.classId]
        const month = timeslots.find(t => t.date === value)?.month || ""
        await formik.setFieldValue("month", month);
    }

    return (
        <>
            <Button
                variant="contained"
                onClick={clickAdd}
            >
                新增
            </Button>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={studentLeaveRecordList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                // checkboxSelection
                // onRowSelectionModelChange={handleRowSelectionChange}
            />

            {/* Details Modal */}
            <ConfirmModal title={`${editingStudentLeaveRecord ? '修改' : '新增'}請假記錄`}
                          open={openModal}
                          onClose={handleCloseModal}
                          confirmButtonTitle={'儲存'}
                          confirmButtonProps={{
                              disabled: !formik.dirty || formik.isSubmitting
                          }}
                          onSubmit={formik.handleSubmit}>

                <StudentSelect
                    sx={{ mt: 2 }}
                    size="small"
                    fullWidth={true}
                    value={formik.values.studentId || null}
                    onChange={handleStudentChanged}
                    onBlur={formik.handleBlur}
                    textFieldProps={{
                        name: "studentId",
                        label: "學生",
                        error: !!formik.errors.studentId && formik.touched.studentId,
                        helperText: formik.errors.studentId
                    }}
                ></StudentSelect>

                <CourseSelect
                    allowedCourseIds={allowedCourseIds}
                    sx={{ mt: 2 }}
                    name="courseId"
                    label="課程"
                    size="small"
                    fullWidth={true}
                    value={formik.values.courseId === -1 || !formik.values.courseId ? "" : formik.values.courseId}
                    onChange={formik.handleChange}
                    onCourseChanged={courseChanged}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.courseId && formik.touched.courseId}
                    helperText={formik.errors.courseId}
                ></CourseSelect>

                {
                    timeslotLookup && timeslotLookup[formik.values.courseId] && (<TextField
                        sx={{ mt: 2 }}
                        type="number"
                        name="classId"
                        label="班次"
                        size="small"
                        fullWidth={true}
                        value={formik.values.classId || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!formik.errors.classId && formik.touched.classId}
                        helperText={formik.errors.classId}
                        select>
                        {
                            Object.keys(timeslotLookup[formik.values.courseId]).map(classId => (
                                <MenuItem key={classId} value={Number(classId)}>
                                    { classId }
                                    {/*{ `${classDef.classId} - ${dayjs().weekday(classDef.weekday).toDate().toLocaleDateString('zh-HK', { weekday: 'long' })}` }*/}
                                    {/*{ `${dayjs(classDef.start).format('HH:mm')} - ${dayjs(classDef.end).format('HH:mm')}` }*/}
                                </MenuItem>
                            ))
                        }
                    </TextField>)
                }

                {
                    timeslotLookup && timeslotLookup[formik.values.courseId] && timeslotLookup[formik.values.courseId][formik.values.classId] && (<TextField
                        sx={{ mt: 2 }}
                        name="date"
                        label="日期"
                        size="small"
                        fullWidth={true}
                        value={formik.values.date || ''}
                        onChange={(e) => handleDateChanged(e, e.target.value)}
                        onBlur={formik.handleBlur}
                        error={!!formik.errors.date && formik.touched.date}
                        helperText={formik.errors.date}
                        select>
                        {
                            timeslotLookup[formik.values.courseId][formik.values.classId].map(timeslot => (
                                <MenuItem key={timeslot.date} value={timeslot.date}>
                                    { timeslot.date }
                                </MenuItem>
                            ))
                        }
                    </TextField>)
                }

            </ConfirmModal>
        </>
    )
}