import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Root from "@/routes/root";
import ErrorPage from "./error";
import BlogList from "@/routes/blog-list";
import UserList from "@/routes/user-list";
import BannerList from "@/routes/banner-list";
import AdminList from "@/routes/admin-list";
import axios from "axios";
import {configAxiosAutoRefreshTokenInterceptor} from "common/axios";
import {postRefreshToken} from "@/api/admin";
import {QueryClient, QueryClientProvider} from "react-query";
import "common/extensions";
import CourseList from "@/routes/course-list";
import CourseDetail from '@/routes/course-detail';
import TutorList from "@/routes/tutor-list";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Home from "@/routes/home";
import {store} from "@/store";
import {Provider} from "react-redux";
import OrderList from "@/routes/order-list";
import OrderCreate from "@/routes/order-create";
import HomeworkList from "@/routes/homework-list";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";
import HolidayList from "@/routes/holiday-list";
import ExpenseList from '@/routes/expense-list';
import ProductList from "@/routes/product-list";
import ProductDetail from "@/routes/product-detail";
import BlogDetail from './routes/blog-detail';
import ProductOrderList from "@/routes/product-order-list";
import ProductOrderDetail from './routes/product-order-detail';
import ProductCategoryList from "@/routes/product-category-list";
import AccountingList from "@/routes/accounting";
import TutorLeaveRequestList from "@/routes/tutor-leave-request-list";
import StudentLeaveRecordList from "@/routes/student-leave-record-list";
import Dashboard from "@/routes/dashboard";
import UnpaidStudentList from "@/routes/unpaid-student-list";
import WhatsappRecordList from "@/routes/whatsapp-record-list";
import Recommendation from "@/routes/recommendation";
import ReferralRecordsList from "./routes/referral-list";
import SalesList from "@/routes/sales-list";
import StudentHomeworkList from "@/routes/student-homework-list";

dayjs.extend(weekday);
dayjs.extend(isoWeek);

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
configAxiosAutoRefreshTokenInterceptor(
    axios,
    process.env.REACT_APP_CREDENTIAL_KEY || 'Credential',
    async (refreshToken) => {
        const res = await postRefreshToken({refreshToken});
        return {
            accessExpiresIn: res.data.accessExpiresIn,
            accessToken: res.data.accessToken,
        }
    }
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <Home />,
            },
            {
                path: "banners",
                element: <BannerList />,
            },
            {
                path: "users",
                element: <UserList />,
            },
            {
                path: "admins",
                element: <AdminList />,
            },
            {
                path: "tutors",
                element: <TutorList />,
            },
            {
                path: "student-leave-requests",
                element: <StudentLeaveRecordList />,
            },
            {
                path: "tutor-leave-requests",
                element: <TutorLeaveRequestList />,
            },
            {
                path: "blogs",
                element: <BlogList />,
            },
            {
                path: "blogs/edit/:id",
                element: <BlogDetail />,
            },
            {
                path: "blogs/add",
                element: <BlogDetail />,
            },
            {
                path: "courses",
                element: <CourseList />,
            },
            {
                path: "courses/edit/:id",
                element: <CourseDetail />,
                // children: [
                //     {
                //         path: "lesson",
                //         element: <CourseLesson />,
                //     }
                // ]
            },
            {
                path: "courses/add",
                element: <CourseDetail />,
            },
            {
                path: "orders",
                element: <OrderList />,
            },
            {
                path: "orders/edit/:id",
                element: <OrderCreate />,
            },
            {
                path: "orders/add",
                element: <OrderCreate />,
            },
            {
                path: "homeworks",
                element: <HomeworkList />,
            },
            {
                path: "student-homeworks",
                element: <StudentHomeworkList />,
            },
            {
                path: "holidays",
                element: <HolidayList />,
            },
            {
                path: "expenses",
                element: <ExpenseList />,
            },
            {
                path: "sales",
                element: <SalesList />,
            },
            {
                path: "accounting",
                element: <AccountingList />,
            },
            {
                path: "unpaid-students",
                element: <UnpaidStudentList />,
            },
            {
                path: "products",
                element: <ProductList />,
            },
            {
                path: "products/edit/:id",
                element: <ProductDetail />,
            },
            {
                path: "products/add",
                element: <ProductDetail />,
            },
            {
                path: "product-orders",
                element: <ProductOrderList />,
            },
            {
                path: "product-orders/edit/:id",
                element: <ProductOrderDetail />,
            },
            {
                path: "product-orders/add",
                element: <ProductOrderDetail />,
            },
            {
                path: "product-categories",
                element: <ProductCategoryList />,
            },
            {
                path: "dashboard",
                element: <Dashboard />,
            },
            {
                path: "whatsapp-records",
                element: <WhatsappRecordList />
            },
            {
                path: "recommendation",
                element: <Recommendation />
            },
            {
                path: "referral-records",
                element: <ReferralRecordsList />
            }
        ],
    },
]);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 0,
            retry: false,
            refetchOnWindowFocus: false
        }
    }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

document.getElementById('root')?.setAttribute('style', 'min-height: 100vh; display: flex; flex-direction: column;')

root.render(
  // <React.StrictMode>
      <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Provider store={store}>
                  <RouterProvider router={router} />
              </Provider>
          </LocalizationProvider>
      </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
